import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApisZonesService } from 'src/app/services/zones/apis-zones.service';
import Swal from 'sweetalert2';
import { Zone } from '../../models/home';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {

  zones : Zone[];
  ids : number[] =[]; 
  loading =true;
  constructor(private http :ApisZonesService ,private router: Router) {
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }
  ngOnInit(): void {
    this.http.getZones().subscribe(response =>{
      this.zones = response.data
      this.loading = false
    })
  
  }


  clickSweet(id : number){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deleteZones(id).subscribe(response =>{
          if(response.status == 1){
            this.http.getZones().subscribe(response =>{
              this.zones = response.data;
            })
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  onChildAdded(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getZones().subscribe(response =>{
      this.zones = response.data;
    })
  }

  selectItem(e, item){
    if(e)
    this.ids.push(item.id);
    else{
      let index = this.ids.indexOf(item.id)
      this.ids.splice(index , 1);
    }
  }

  deleteSelected(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover these Data again!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deleteSelected(this.ids).subscribe(response =>{
          if(response.status == 1){
            this.http.getZones().subscribe(response =>{
              this.zones = response.data;
            })
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
}
