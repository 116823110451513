    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <h3>products
                                <span class="search">
                                    <input type="text" class="searchTerm" placeholder="search by name or id " (input)="onSearchChange($event.target.value)">
                                    <button type="submit" class="searchButton">
                                      <i class="fa fa-search"></i>
                                   </button>
                                 </span>
                                <span class="buttonModale">
                                    <button type="button" class="btn btn-danger waves-effect waves-light mr_20" (click)="deleteSelected()"><i class="iconePlus fa fa-trash"></i>Delete Selected</button>
                                </span>
                            </h3>
                        </div>
                        <div class="table-rep-plugin">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table id="tech-companies-1" class="table  table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>select</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody  *ngIf='!loading'>
                                    <tr *ngFor='let item of products'>
                                        <th>{{item.id}}</th>
                                        <th><input type="checkbox" #checkboxes type="checkbox" name="chek" data-md-icheck ngModel (ngModelChange)="selectItem($event,item)" ></th>
                                        <th><img [src]="item?.image"></th>
                                        <th> {{item?.name}} </th>
                                        <th>{{item?.price}}</th>
                                        <th>{{item?.desc}}</th>
                                        <td>
                                            <ul class="StatusIcon">
                                                <li class="delete" (click)='clickSweet(item.id)'><i class="fa fa-trash"></i></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="responsiveLoading" *ngIf='loading'>
                                    <span><i class="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div> <!-- container -->
    </div> <!-- content -->


