import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApisProductsService } from 'src/app/services/category/apis-products.service';
import Swal from 'sweetalert2';
import { DataProductes } from '../../models/product_categories';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products :DataProductes[];
  ids : number[] =[];  
  loading =true;
  formBody : FormData = new FormData;
  constructor(private http :ApisProductsService, private router :Router) { 
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
    this.http.getAllProducts().subscribe(response =>{
      this.products = response.data
      this.loading = false;
    })
   }
  ngOnInit(): void {

  
  }
  onChildShow(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getAllProducts().subscribe(response =>{
      this.products = response.data;
    })
  }
  clickSweet(id){
   this.formBody.append('id' ,id),
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deleteProducts(this.formBody).subscribe(response =>{
          if(response.status == 1){
            this.http.getAllProducts().subscribe(response =>{
              this.products = response.data;
            })
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

  onChildAdded(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getAllProducts().subscribe(response =>{
      this.products = response.data;
    })
  }

  selectItem(e, item){
    if(e)
    this.ids.push(item.id);
    else{
      let index = this.ids.indexOf(item.id)
      this.ids.splice(index , 1);
    }
  }

  deleteSelected(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover these Data again!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.http.deleteSelected(this.ids).subscribe(response =>{
          if(response.status == 1){
            this.http.getAllProducts().subscribe(response =>{
              this.products = response.data;
            })
            Swal.fire(
              'Deleted!',
              'Your imaginary file has been deleted.',
              'success'
            )
          }
        })
        
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  onSearchChange(searchValue: string): void {  
    console.log(searchValue);
    this.loading = true;
    this.http.getAllProducts(searchValue).subscribe(response =>{
      this.products = response.data;
      if(response.status == 1){
        this.loading = false
      }
    })
  }

}
