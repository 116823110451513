<button type="button" class="btn btn-danger waves-effect waves-light mr_20" data-toggle="modal" data-target="#bs-example-modal-lg"><i class="iconePlus fa fa-trash"></i>Delete All Data</button>
<!--  Modal content for the above example -->
<div class="modal" id="bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" #showModale>
    <div class="modal-dialog modal-xs">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" #closeButton>×</button>
                <h4 class="modal-title" id="myLargeModalLabel">please Enter Password to Remove Data</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="card-box">
                        <form  #data="ngForm" (ngSubmit)='deleteData(data)'>
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="warinig_msg">this action will affect large amount of data please add your password to authinticat that is your design to remove all data in the system</h6>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="userName">Password<span class="text-danger">*</span></label>
                                        <input type="password"  required  class="form-control"  name="password" ngModel>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                            </div>  
                            <div class="form-group text-right m-b-0">
                                <button class="btn btn-custom waves-effect waves-light AddUser" type="submit" [disabled]="loading">
                                    Delete All Data
                                </button>
                                <button type="reset" class="btn btn-light waves-effect m-l-5" data-dismiss="modal" aria-hidden="true">
                                    Cancel
                                </button>
                            </div>

                        </form>
                    </div> <!-- end card-box -->
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->