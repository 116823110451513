import { Component, OnInit, Output, ViewChild ,EventEmitter} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisAuthService } from 'src/app/services/apis-auth.service';
// import { EventEmitter } from 'events';

@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.scss']
})
export class DeleteDataComponent implements OnInit {

  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  loading = false
  constructor( private http : ApisAuthService) { }
  
  @Output() childAdded: EventEmitter<string> =   new EventEmitter();

  ngOnInit(): void {
  }

  deleteData(body :NgForm){
    this.loading = true
    this.errorMessage =""
      this.http.deleteData(body.value).subscribe(response=>{ 
        this.loading = false
            if(response.status == 1 ){
              body.resetForm();
              this.closeButton.nativeElement.click();
                this.childAdded.emit('delete All Data Successfully');
                this.removeFaild();

            }
            else{
              this.errorMessage ='* '+ response.message;
            }
      })
  }
  removeFaild(){

  }
}
