import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiOrdersService } from 'src/app/services/Order/api-orders.service';
import Swal from 'sweetalert2';
import { DataOrDer } from '../../models/order';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orders :DataOrDer[];
  ids : number[] =[];  
  loading =true;
  constructor(private http :ApiOrdersService, private router :Router) { 
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
    this.http.getAllOrders().subscribe(response =>{
      this.orders = response.data
      this.loading = false;
      console.log("mjnhgf")

    })
   }
  ngOnInit(): void {

  
  }

  selectItem(e, item){
    if(e)
    this.ids.push(item.user_id);
    else{
      let index = this.ids.indexOf(item.user_id)
      this.ids.splice(index , 1);
    }
    console.log(this.ids)
  }
  onChildShow(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getAllOrders().subscribe(response =>{
      this.orders = response.data;
    })
  }
  getColor(status :number){
   
    switch(status){
            case 1 :
                return "#F3A521";
            case 2 :
                return "#001EED";
            case 3 :
                return "#6ED540";
            case 4 :
                return "#F14272";
            default:
                return "#F3A521";
        }
  }

  getStatus(status :number){
    switch(status){
      case 1 :
          return "قيد الانتظار";
      case 2 :
          return "تم التجهيز";
      case 3 :
          return "تم الاستلام";
      case 4 :
          return "تم الرفض";
      default:
          return "قيد الانتظار";
    }
    

  }


  getType(status : number){
    switch(status){
      case 1 :
          return "توصيل";
      case 2 :
          return "استلام من المكتب";
      case 3 :
          return "صاله";
      default:
        return "توصيل";
    }

  }
  onChildStauts(e){
    
    Swal.fire(
      'Success',
      e,
      'success'
    )
    this.http.getAllOrders().subscribe(response =>{
      this.orders = response.data;
    })
  }
  onNotificationSend(e){
    Swal.fire(
      'Sent',
      e,
      'success'
    )

  }

}
