import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisAuthService } from 'src/app/services/apis-auth.service';
import Swal from 'sweetalert2';
import { Color } from '../../models/color';

@Component({
  selector: 'app-add-color',
  templateUrl: './add-color.component.html',
  styleUrls: ['./add-color.component.scss']
})
export class AddColorComponent implements OnInit {

  loading = true;
  oldColor : Color[]
  loadingButtonColor = false;
  errorMessage ;
  linkColor ;
  selectedItem ;
  selectedelivery
  selectedcollection
  selectedplace
  bodyData : FormData = new FormData;
  currency 
  constructor(private http : ApisAuthService) { }

  ngOnInit(): void {
    this.http.getColor().subscribe(response =>{
      
      this.linkColor = response.data;
      this.loading = false;
      this.selectedItem = this.linkColor.using_printer ==1 
      this.selectedelivery  = this.linkColor.enable_delivery ==1 
      this.selectedcollection = this.linkColor.enable_collection==1 
      this.selectedplace = this.linkColor.enable_place==1 
      this.choosdelivery(response.data.enable_delivery == 1);
      this.choosecollection(response.data.enable_collection == 1);
      this.chooseplace(response.data.enable_place == 1);
      this.chooseItem(response.data.using_printer == 1)
    });
    this.http.getCurrency().subscribe(response =>{
      this.currency = response.data;
    })
  }
  AddColorSUbmit(){
    this.loadingButtonColor = true
    this.bodyData.append('color' ,this.linkColor.color ),
    this.bodyData.append('code' ,this.linkColor.busniss_code ),
    this.bodyData.append('delivery_discount' ,this.linkColor.delivery_discount ),
    this.bodyData.append('collection_discount' ,this.linkColor.collection_discount ),
    this.bodyData.append('place_discount' ,this.linkColor.place_discount ),
    this.bodyData.append('min_delivery_discount_cost' ,this.linkColor.min_delivery_discount_cost ),
    this.bodyData.append('min_collection_discount_cost' ,this.linkColor.min_collection_discount_cost ),
    this.bodyData.append('min_place_discount_cost' ,this.linkColor.min_place_discount_cost ),
    this.bodyData.append('min_delivery_order_amount' ,this.linkColor.min_delivery_order_amount ),
    this.bodyData.append('toggle_show_qty' ,this.linkColor.busniss_toggle_show_qty ),
    this.bodyData.append('decimal_point' ,this.linkColor.decimal_point ),
    this.bodyData.append('apply_qty' ,this.linkColor.apply_qty ),
    this.bodyData.append('currency_id' ,this.linkColor.currency_id ),
    this.http.addColor(this.bodyData).subscribe(response =>{
      this.loadingButtonColor = false;
      if(response.status == 1){
        Swal.fire(
          'settinges is Successfully'
        )
      }else{
        this.errorMessage ='* '+ response.message;
      }
      
    })
  }
  selectItem(color){
    this.linkColor.color = color;
  }
  
  chooseItem(select){
      let cheekData = select ? "1" : "0"
      if(this.bodyData.has("using_printer")){
        this.bodyData.set('using_printer' ,cheekData);
      }
      else{
        this.bodyData.append('using_printer' ,cheekData);
      }
      
  }
  choosdelivery(select){
    let cheekData = select ? "1" : "0"
    if(this.bodyData.has("enable_delivery")){
      this.bodyData.set('enable_delivery' ,cheekData);
    }
    else{
      this.bodyData.append('enable_delivery' ,cheekData);
    }
  }

  choosecollection(select){
    let cheekData = select ? "1" : "0"
    if(this.bodyData.has("enable_collection")){
      this.bodyData.set('enable_collection' ,cheekData);
    }
    else{
      this.bodyData.append('enable_collection' ,cheekData);
    }
  }

  chooseplace(select){
    let cheekData = select ? "1" : "0"
    if(this.bodyData.has("enable_place")){
      this.bodyData.set('enable_place' ,cheekData);
    }
    else{
      this.bodyData.append('enable_place' ,cheekData);
    }
  }
  onChildAdded(e){
    Swal.fire(
      'Success',
      e,
      'success'
    )
  }
}
